<template>
  <div class="single-sensor" v-if="loaded">
    <h2 v-if="sensorDetails.name" class="mb-4">
      {{ sensorDetails.name }}
      <v-icon medium :color="sensorDetails.online ? 'green' : 'red'">mdi-checkbox-blank-circle</v-icon>
    </h2>
    <h2 v-else-if="sensorDetails.label" class="mb-4">
      {{ sensorDetails.label }}
      <v-icon medium :color="sensorDetails.online ? 'green' : 'red'">mdi-checkbox-blank-circle</v-icon>
    </h2>
    <Chart v-if="currentData.length" ref="chart" :chart-data="chartdata" :options="otherOptions" />
    <template v-if="deviceType === 'KDX'">
      <v-layout row wrap mt-5 class="kdx">
        <v-flex xs12 md6 pa-2 align-center>
          <v-card color="#2B9EB3" height="100%">
            <v-layout align-center justify-space-around wrap fill-height>
              <v-flex shrink>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.H20Pressure"
                  readonly
                  label="H20 Pressure"
                ></v-switch>
              </v-flex>
              <v-flex shrink>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.C02Pressure"
                  readonly
                  label="C02 Pressure"
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 md6 pa-2>
          <v-card color="#3EA6B9">
            <v-layout>
              <v-flex xs12>
                <v-layout wrap row>
                  <v-flex offset-xs2 offset-sm3>
                    <v-switch
                      color="#fff"
                      :input-value="latestSensor.leftCompContCoil"
                      readonly
                      label="Left Cont Coil"
                    ></v-switch>
                  </v-flex>
                  <v-flex offset-xs2 offset-sm3>
                    <v-switch
                      color="#fff"
                      :input-value="latestSensor.leftHighPressureCoil"
                      readonly
                      label="Left HP Coil"
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap row>
                  <v-flex offset-xs2 offset-sm3>
                    <v-switch
                      color="#fff"
                      :input-value="latestSensor.rightCompContCoil"
                      readonly
                      label="Right Cont Coil"
                    ></v-switch>
                  </v-flex>
                  <v-flex offset-xs2 offset-sm3>
                    <v-switch
                      color="#fff"
                      :input-value="latestSensor.rightHighPressureCoil"
                      readonly
                      label="Right HP Coil"
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout wrap row class="kdx">
        <v-flex xs6 md3 pa-2 order-md-1 order-xs1>
          <v-card color="#55B676">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b1Water"
                  readonly
                  label="B1 Water"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b1BIB" readonly label="B1 BIB"></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b1C02" readonly label="B1 CO2"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-2 order-xs2>
          <v-card color="#04a896">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b2Water"
                  readonly
                  label="B2 Water"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b2BIB" readonly label="B2 BIB"></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b2C02" readonly label="B2 CO2"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-3 order-xs5>
          <v-card color="#7A81B1">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b3Water"
                  readonly
                  label="B3 Water"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b3BIB" readonly label="B3 BIB"></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b3C02" readonly label="B3 CO2"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-4 order-xs6>
          <v-card color="#E56D51">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b4Water"
                  readonly
                  label="B4 Water"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b4BIB" readonly label="B4 BIB"></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b4C02" readonly label="B4 CO2"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-5 order-xs3>
          <v-card color="#66BD84">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b1Defrost"
                  readonly
                  label="B1 Defrost"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b1Liquid"
                  readonly
                  label="B1 Liquid"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b1Syrup"
                  readonly
                  label="B1 Syrup"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b1Fill" readonly label="B1 Fill"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-6 order-xs4>
          <v-card color="#1AAF9F">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b2Defrost"
                  readonly
                  label="B2 Defrost"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b2Liquid"
                  readonly
                  label="B2 Liquid"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b2Syrup"
                  readonly
                  label="B2 Syrup"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b2Fill" readonly label="B2 Fill"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-7 order-xs7>
          <v-card color="#868CB8">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b3Defrost"
                  readonly
                  label="B3 Defrost"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b3Liquid"
                  readonly
                  label="B3 Liquid"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b3Syrup"
                  readonly
                  label="B3 Syrup"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b3Fill" readonly label="B3 Fill"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-8 order-xs8>
          <v-card color="#E77A60">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b4Defrost"
                  readonly
                  label="B4 Defrost"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b4Liquid"
                  readonly
                  label="B4 Liquid"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b4Syrup"
                  readonly
                  label="B4 Syrup"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b4Fill" readonly label="B4 Fill"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
    <template v-else-if="deviceType === 'MDX'">
      <v-container fluid class="mt-4">
        <v-row justify="start">
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <h2 class="mb-2">Data</h2>
                <v-btn class="ma-1" :loading="realtimeButton" @click="realTime">Realtime</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="oneDayButton"
                  @click="getDateReadings(1, 'days')"
                >24 hours</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="threeDayButton"
                  @click="getDateReadings(3, 'days')"
                >3 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="sevenDayButton"
                  @click="getDateReadings(7, 'days')"
                >7 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="tenDayButton"
                  @click="getDateReadings(10, 'days')"
                >10 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="thirtyDayButton"
                  @click="getDateReadings(30, 'days')"
                >30 days</v-btn>
              </v-col>
              <v-col cols="12">
                <v-date-picker
                  min="2020-06-01"
                  :max="(new Date()).toISOString()"
                  color="#012b45"
                  v-model="dates"
                  range
                  width="310"
                ></v-date-picker>
              </v-col>
              <v-col cols="12">
                <download-csv :data="csvData" name="readings.csv">
                  <v-btn color="#012b45" outlined>
                    <v-icon color="#012b45" left>mdi-chart-line</v-icon>Export Data to CSV
                  </v-btn>
                </download-csv>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <Alarm />
              </v-col>
              <v-col cols="12">
                <Alert />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="deviceType === 'TDX'">
      <v-container fluid class="mt-4">
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <h2 class="mb-2">Data</h2>
                <v-btn class="ma-1" :loading="realtimeButton" @click="realTime">Realtime</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="oneDayButton"
                  @click="getDateReadings(1, 'days')"
                >24 hours</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="threeDayButton"
                  @click="getDateReadings(3, 'days')"
                >3 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="sevenDayButton"
                  @click="getDateReadings(7, 'days')"
                >7 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="tenDayButton"
                  @click="getDateReadings(10, 'days')"
                >10 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="thirtyDayButton"
                  @click="getDateReadings(30, 'days')"
                >30 days</v-btn>
              </v-col>
              <v-col cols="12">
                <v-date-picker
                  min="2020-06-01"
                  :max="(new Date()).toISOString()"
                  color="#012b45"
                  v-model="dates"
                  range
                  width="310"
                ></v-date-picker>
              </v-col>
              <v-col cols="12">
                <download-csv :data="csvData" name="readings.csv">
                  <v-btn color="#012b45" outlined>
                    <v-icon color="#012b45" left>mdi-chart-line</v-icon>Export Data to CSV
                  </v-btn>
                </download-csv>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <Alarm />
              </v-col>
              <v-col cols="12">
                <Alert />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Chart from "@/components/Chart";
import Alert from "@/components/Alert";
import Alarm from "@/components/Alarm";
export default {
  name: "demo",
  data() {
    return {
      loaded: false,
      dates: [],
      realtime: true,
      realtimeButton: false,
      twelveHourButton: false,
      oneDayButton: false,
      twoDayButton: false,
      threeDayButton: false,
      sevenDayButton: false,
      tenDayButton: false,
      fourteenDayButton: false,
      thirtyDayButton: false,
      currentData: [],
      dayReadings: "",
      chartdata: null,
      chartLow1: 33,
      chartLow2: 30,
      chartHigh1: 41,
      chartHigh2: 44,
      sensorData: true,
      kdxOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                max: 200,
              },
            },
          ],
        },
      },
      colors: [
        "#13293D",
        "#006494",
        "#247BA0",
        "#1B98E0",
        "#21897E",
        "#3BA99C",
        "#554971",
        "#63768D",
        "#F08700",
        "#AD5D4E",
        "#353535",
        "#561F37",
        "#72195A",
      ],
      tdxValues: ["min"],
      kdxValues: [
        "b1Temp",
        "b2Temp",
        "b3Temp",
        "b4Temp",
        "compSuctionTemp",
        "compReturnTemp",
        "ambient",
      ],
      mdxValues: [
        "temp1",
        "temp2",
        "temp3",
        "temp4",
        "pressure1",
        "pressure2",
        "pressure3",
        "pressure4",
        "pressure5",
        "pressure6",
        "voltage1",
        "voltage2",
        "voltage3",
      ],
      otherOptions: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "minute",
                tooltipFormat: "MMM D, h:mm a",
                stepSize: 5,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                // suggestedMin: 30,
                // suggestedMax: 40,
                // min: 30,
                // max: 50,
                // stepSize: 2
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    };
  },
  components: {
    Chart,
    Alert,
    Alarm,
  },
  async created() {
    let mdx = false;
    let type = await this.$store.dispatch(
      "sensor/getSensorInfo",
      "EADE2FF3B8C888EFBD3E96E3680AE2FFFEF93D5C"
    );
    if (type.device.model === "mdx") mdx = true;

    if (mdx) {
      await this.$store.dispatch(
        "sensor/singleMDXSensor",
        this.$route.params.id
      );
    } else {
      await this.$store.dispatch("sensor/singleSensor", "EADE2FF3B8C888EFBD3E96E3680AE2FFFEF93D5C");
    }

    this.setChartVals();
    this.setData();
    this.loaded = true;
  },
  computed: {
    ...mapState("sensor", ["sensor", "deviceType", "sensorDetails"]),
    latestValues() {
      return this.currentData.map((item) => {
        return {
          val: item.min,
        };
      });
    },
    values() {
      if (this.deviceType) {
        if (this.deviceType === "MDX") {
          return this.mdxValues;
        } else if (this.deviceType === "KDX") {
          return this.kdxValues;
        } else return this.tdxValues;
      } else return [];
    },
    allData() {
      if (!this.values.length) return [];
      return this.values.map((item, i, arr) => {
        return {
          name: item,
          readings: this.currentData.map((cur) => {
            return {
              createdAt: cur.createdAt,
              [item]: cur[item],
            };
          }),
        };
      });
    },
    allDatasets() {
      return this.allData.map((item, i) => {
        return {
          label: `${item.name === "min" ? "temp" : item.name}`,
          borderColor: (context) => {
            if (item.name !== "min") {
              return this.colors[i];
            } else {
              return this.chartGradient(context);
            }
          },
          backgroundColor: (context) => {
            if (item.name !== "min") {
              return this.colors[i];
            } else {
              return this.chartGradient(context);
            }
          },
          radius: 0,
          borderWidth: 2,
          fill: false,
          data: item.readings.map((item2) => {
            return {
              x: new Date(item2.createdAt),
              y: item2[item.name],
            };
          }),
        };
      });
    },
    pickerWidth() {
      return "290px";
    },
    csvData() {
      if (this.deviceType === "TDX") {
        return this.currentData.map((item) => {
          return {
            date: item.createdAt,
            temp: item.min,
          };
        });
      } else if (this.deviceType === "MDX") {
        return this.currentData.map((item) => {
          return {
            date: item.createdAt,
            temp1: item.temp1,
            temp2: item.temp2,
            temp3: item.temp3,
            temp4: item.temp4,
            pressure1: item.pressure1,
            pressure2: item.pressure2,
            pressure3: item.pressure3,
            pressure4: item.pressure4,
            pressure5: item.pressure5,
            pressure6: item.pressure6,
            voltage1: item.voltage1,
            voltage2: item.voltage2,
            voltage3: item.voltage3,
          };
        });
      } else if (this.deviceType === "KDX") {
        return this.currentData.map((item) => {
          return {
            date: item.createdAt,
            b1Temp: item.b1temp,
            b2temp: item.b2temp,
            b3temp: item.b3temp,
            b4temp: item.b4temp,
            compSuctionTemp: item.compSuctionTemp,
            compReturnTemp: item.compReturnTemp,
            ambient: item.ambient,
          };
        });
      }
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    sensorDefaults() {
      return {
        high: this.sensor[0].sensor.high,
        low: this.sensor[0].sensor.low,
        time: this.sensor[0].sensor.time,
        email: this.sensor[0].sensor.email,
        phone: this.sensor[0].sensor.phone,
      };
    },
    high() {
      return Math.max(
        ...[].concat(
          ...this.currentData.map((item) => {
            return Object.values(item).filter(
              (item2) => typeof item2 === "number"
            );
          })
        )
      );
    },
    low() {
      return Math.min(
        ...[].concat(
          ...this.currentData.map((item) => {
            return Object.values(item).filter(
              (item2) => typeof item2 === "number"
            );
          })
        )
      );
    },
    latestSensor() {
      if (this.sensor.length && this.deviceType === "KDX") {
        return Object.entries(this.sensor[this.sensor.length - 1])
          .filter((item) => {
            if ((item[1] === 0 || item[1] === 1) && item[0] !== "deviceId")
              return item;
          })
          .reduce((prev, curr) => {
            prev[curr[0]] = Boolean(curr[1]);
            return prev;
          }, {});
      } else return this.sensor[this.sensor.length - 1];
    },
  },
  watch: {
    dates(val) {
      if (val.length > 1) {
        let [from, to] = val;
        this.getCalendarReadings(
          this.$moment(from).toISOString(),
          this.$moment(to).add(1, "day").toISOString()
        );
      }
    },
    sensor(val) {
      if (this.loaded && val.length <= 20) {
        this.setData();
      } else if (this.loaded) {
        this.setData(val);
      }
    },
    dayReadings(val) {
      // let temps = val.map(item => item.min);
      // let high = Math.max(...temps);
      // let low = Math.min(...temps);
      // let readings = val.filter(item => item.min === high || item.min === low);
      // console.log(readings.length);
      // this.setData(val);
    },
    otherOptions: {
      handler(val) {
        if (this.$refs.chart) {
          this.$refs.chart.renderChart(
            this.chartdata,
            this.otherOptions
            // this.deviceType === "KDX" ? this.kdxOptions : this.otherOptions
          );
        }
      },
      deep: true,
    },
    high(val) {
      if ((val - this.low) % 2 === 0) {
        this.otherOptions.scales.yAxes[0].ticks.max = val + 2;
      } else {
        this.otherOptions.scales.yAxes[0].ticks.max = val + 1;
      }
    },
    low(val) {
      this.otherOptions.scales.yAxes[0].ticks.min = val;
    },
  },
  methods: {
    checkName(name) {
      if (name === "min") return "Temp";
      else if (name === "min2") return "Temp2";
      else if (name === "temp1") return "Temp1";
      else if (name === "temp2") return "Temp2";
      else if (name === "temp3") return "Temp3";
      else if (name === "temp4") return "Temp4";
      else if (name === "pressure1") return "Pressure1";
      else if (name === "pressure2") return "Pressure2";
      else if (name === "pressure3") return "Pressure3";
      else if (name === "pressure4") return "Pressure4";
      else if (name === "pressure5") return "Pressure5";
      else if (name === "pressure6") return "Pressure6";
      else if (name === "voltage1") return "Voltage1";
      else if (name === "voltage2") return "Voltage2";
      else if (name === "voltage3") return "Voltage3";
      else return "NA";
    },
    setChartVals() {
      this.chartLow1 = this.sensorDetails.low
        ? this.sensorDetails.low + 3
        : -97;
      this.chartLow2 = this.sensorDetails.low ? this.sensorDetails.low : -100;
      this.chartHigh1 = this.sensorDetails.high
        ? this.sensorDetails.high - 3
        : 97;
      this.chartHigh2 = this.sensorDetails.high ? this.sensorDetails.high : 100;
    },
    async realTime() {
      if (!this.realtime) {
        this.dates = [];
        this.realtimeButton = true;
        await this.$store.dispatch(
          "sensor/singleSensor",
          this.$route.params.id
        );
        this.otherOptions.scales.xAxes[0].time = {
          unit: "minute",
          stepSize: 5,
        };
        this.realtime = true;
        this.realtimeButton = false;
      }
    },
    async getCalendarReadings(from, to) {
      this.realtime = false;
      await this.$store.dispatch("sensor/pullReadings", {
        id: this.sensor[0].readingSensorId,
        from,
        to,
      });
      this.otherOptions.scales.xAxes[0].time = {
        unit: "day",
        stepSize: 1,
      };
    },
    async getDateReadings(number, unit) {
      this.dates = [];
      if (number === 12) {
        this.twelveHourButton = true;
      } else if (number === 1) {
        this.oneDayButton = true;
      } else if (number === 2) {
        this.twoDayButton = true;
      } else if (number === 3) {
        this.threeDayButton = true;
      } else if (number === 7) {
        this.sevenDayButton = true;
      } else if (number === 10) {
        this.tenDayButton = true;
      } else if (number === 14) {
        this.fourteenDayButton = true;
      } else if (number === 30) {
        this.thirtyDayButton = true;
      }
      let readings = await this.$store.dispatch("sensor/pullReadings", {
        id: this.sensor[0].readingSensorId,
        number,
        unit,
      });
      if (!readings) {
        this.twelveHourButton = false;
        this.oneDayButton = false;
        this.twoDayButton = false;
        this.threeDayButton = false;
        this.sevenDayButton = false;
        this.tenDayButton = false;
        this.thirtyDayButton = false;
        this.realtime = false;
      } else if (number === 12) {
        this.twelveHourButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "hour",
          stepSize: 2,
        };
      } else if (number === 1) {
        this.oneDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "hour",
          stepSize: 3,
        };
      } else if (number === 2) {
        this.twoDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1,
        };
      } else if (number === 3) {
        this.threeDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1,
        };
      } else if (number === 7) {
        this.sevenDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1,
        };
      } else if (number === 10) {
        this.tenDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1,
        };
      } else if (number === 14) {
        this.fourteenDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 2,
        };
      } else if (number === 30) {
        this.thirtyDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 3,
        };
      }
      this.realtime = false;
    },
    colorStop(val) {
      let vals = [];

      // green
      if (this.high < this.chartHigh1) {
        vals.push({
          color: "green",
          value: 0,
        });
      }
      // yellow
      else if (this.low >= this.chartHigh1 && this.high <= this.chartHigh2) {
        vals.push({
          color: "#d8d300",
          value: 0,
        });
      }
      // red
      else if (this.low > this.chartHigh2) {
        vals.push({
          color: "red",
          value: 0,
        });
      }
      // green, yellow and red
      else if (this.low < this.chartHigh1 && this.high >= this.chartHigh2) {
        vals.push(
          {
            color: "green",
            value: 0,
          },
          {
            color: "#d8d300",
            value:
              Math.abs(this.low - this.chartHigh1) / (this.high - this.low),
          },
          {
            color: "red",
            value:
              Math.abs(this.low - this.chartHigh2) / (this.high - this.low),
          }
        );
      }
      // green and yellow
      else if (
        this.low < this.chartHigh1 &&
        this.high >= this.chartHigh1 &&
        this.high < this.chartHigh2
      ) {
        vals.push(
          {
            color: "green",
            value: 0,
          },
          {
            color: "#d8d300",
            value:
              Math.abs(this.low - this.chartHigh1) / (this.high - this.low),
          }
        );
      }
      // yellow and red
      else if (this.low >= this.chartHigh1 && this.high >= this.chartHigh2) {
        vals.push(
          {
            color: "#d8d300",
            value: 0,
          },
          {
            color: "red",
            value:
              Math.abs(this.low - this.chartHigh2) / (this.high - this.low),
          }
        );
      }

      return vals;
    },
    colorCheck(color) {
      if (color === "a" || color === "e") {
        return "red";
      } else if (color === "b" || color === "d") {
        return "#d8d300";
      } else {
        return "green";
      }
    },
    colorStop2(val) {
      let vals = [];
      let currVals = this.currentData.map((item) => item.min);
      let valTemps = currVals
        .reduce((acc, cur) => {
          // red high
          if (cur > this.chartHigh2) {
            if (!acc.includes("e")) {
              acc.push("e");
            }
          }
          // red low
          else if (cur < this.chartLow2) {
            if (!acc.includes("a")) {
              acc.push("a");
            }
          }
          // yellow high
          else if (cur >= this.chartHigh1 && cur <= this.chartHigh2) {
            if (!acc.includes("d")) {
              acc.push("d");
            }
          }
          // yellow low
          else if (cur >= this.chartLow2 && cur <= this.chartLow1) {
            if (!acc.includes("b")) {
              acc.push("b");
            }
          }
          // green
          else {
            if (!acc.includes("c")) {
              acc.push("c");
            }
          }
          return acc;
        }, [])
        .sort();
      // let colors = valTemps.map(item => this.colorCheck(item));

      if (valTemps.length === 5) {
        vals.push(
          {
            color: "red",
            value: 0,
          },
          { color: "red", value: 1 }
        );
      }

      // // green
      // if (this.high < this.chartHigh1) {
      //   vals.push({
      //     color: "green",
      //     value: 0
      //   });
      //   // console.log("all green");
      // }
      // // yellow high
      // else if (this.low >= this.chartHigh1 && this.high <= this.chartHigh2) {
      //   vals.push({
      //     color: "#d8d300",
      //     value: 0
      //   });
      // }
      // // red
      // else if (this.low > this.chartHigh2) {
      //   vals.push({
      //     color: "red",
      //     value: 0
      //   });
      // }
      // // green, yellow and red
      // else if (this.low < this.chartHigh1 && this.high >= this.chartHigh2) {
      //   vals.push(
      //     {
      //       color: "green",
      //       value: 0
      //     },
      //     {
      //       color: "#d8d300",
      //       value: Math.abs(this.low - this.chartHigh1) / (this.high - this.low)
      //     },
      //     {
      //       color: "red",
      //       value: Math.abs(this.low - this.chartHigh2) / (this.high - this.low)
      //     }
      //   );
      // }
      // // green and yellow
      // else if (
      //   this.low < this.chartHigh1 &&
      //   this.high >= this.chartHigh1 &&
      //   this.high < this.chartHigh2
      // ) {
      //   vals.push(
      //     {
      //       color: "green",
      //       value: 0
      //     },
      //     {
      //       color: "#d8d300",
      //       value: Math.abs(this.low - this.chartHigh1) / (this.high - this.low)
      //     }
      //   );
      // }
      // // yellow and red
      // else if (this.low >= this.chartHigh1 && this.high >= this.chartHigh2) {
      //   vals.push(
      //     {
      //       color: "#d8d300",
      //       value: 0
      //     },
      //     {
      //       color: "red",
      //       value: Math.abs(this.low - this.chartHigh2) / (this.high - this.low)
      //     }
      //   );
      // }
      console.log(vals);
      return vals;
    },
    chartGradient(context) {
      let gradient = null;
      let width = null;
      let height = null;
      let chartArea = context.chart.chartArea;
      if (!chartArea) {
        return null;
      }
      let chartWidth = chartArea.right - chartArea.left;
      let chartHeight = chartArea.bottom - chartArea.top;
      if (gradient === null || width !== chartWidth || height !== chartHeight) {
        width = chartWidth;
        height = chartHeight;
        let ctx = context.chart.ctx;
        gradient = ctx.createLinearGradient(
          0,
          chartArea.bottom,
          0,
          chartArea.top
        );

        let colors = this.colorStop();
        colors.forEach((item) => {
          gradient.addColorStop(item.value, item.color);
        });
      }
      return gradient;
    },
    getLatestValues() {
      return this.chartdata.datasets.map((item) => {
        return item.data[item.data.length - 1];
      });
    },
    setData(data) {
      if (!data) {
        this.currentData = this.sensor.slice(-20);
      } else {
        this.currentData = data;
      }
      // let [latest] = this.currentData.slice(-1);
      this.chartdata = {
        datasets: this.allDatasets,
      };
    },
    // setData(data) {
    //   if (!data) {
    //     this.currentData = this.sensor.slice(-20);
    //   } else {
    //     this.currentData = data;
    //   }
    //   let [latest] = this.currentData.slice(-1);
    //   if (this.deviceType === "KDX") {
    //     this.chartdata = {
    //       labels: this.currentData.map((item) => {
    //         let date = new Date(item.createdAt);
    //         return `${date.getMonth() + 1}/${date.getDate()} - ${
    //           date.getHours() < 13 ? date.getHours() : date.getHours() - 12
    //         }:${
    //           date.getMinutes() < 10
    //             ? "0" + date.getMinutes()
    //             : date.getMinutes()
    //         }`;
    //       }),
    //       datasets: [
    //         {
    //           // label: `B1 - ${latest.b1Temp}℉`,
    //           label: `B1`,
    //           borderColor: "#05668D",
    //           backgroundColor: "#05668D",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.b1Temp),
    //         },
    //         {
    //           // label: `B2 - ${latest.b2Temp}℉`,
    //           label: `B2`,
    //           borderColor: "#050290",
    //           backgroundColor: "#050290",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.b2Temp),
    //         },
    //         {
    //           // label: `B3 - ${latest.b3Temp}℉`,
    //           label: `B3`,
    //           borderColor: "#00A896",
    //           backgroundColor: "#00A896",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.b3Temp),
    //         },
    //         {
    //           // label: `B4 - ${latest.b4Temp}℉`,
    //           label: `B4`,
    //           borderColor: "#00bcbd",
    //           backgroundColor: "#00bcbd",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.b4Temp),
    //         },
    //         {
    //           // label: `Comp Suction - ${latest.compSuctionTemp}℉`,
    //           label: `Comp Suction`,
    //           borderColor: "#E76F51",
    //           backgroundColor: "#E76F51",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.compSuctionTemp),
    //         },
    //         {
    //           // label: `Comp Return - ${latest.compReturnTemp}℉`,
    //           label: `Comp Return`,
    //           borderColor: "#E71D36",
    //           backgroundColor: "#E71D36",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.compReturnTemp),
    //         },
    //         {
    //           // label: `Ambient - ${latest.ambient}℉`,
    //           label: `Ambient`,
    //           borderColor: "#008ad6",
    //           backgroundColor: "#008ad6",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.ambient),
    //         },
    //       ],
    //     };
    //   } else if (this.deviceType === "MDX") {
    //     this.chartdata = {
    //       labels: this.currentData.map((item) => {
    //         let date = new Date(item.createdAt);
    //         return `${date.getMonth() + 1}/${date.getDate()} - ${
    //           date.getHours() < 13 ? date.getHours() : date.getHours() - 12
    //         }:${
    //           date.getMinutes() < 10
    //             ? "0" + date.getMinutes()
    //             : date.getMinutes()
    //         }`;
    //       }),
    //       datasets: [
    //         {
    //           label: `T1`,
    //           borderColor: "#332D64",
    //           backgroundColor: "#332D64",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.temp1),
    //         },
    //         {
    //           label: `T2`,
    //           borderColor: "#DC484E",
    //           backgroundColor: "#DC484E",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.temp2),
    //         },
    //         {
    //           label: `T3`,
    //           borderColor: "#20C997",
    //           backgroundColor: "#20C997",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.temp3),
    //         },
    //         {
    //           label: `T4`,
    //           borderColor: "#0A2F45",
    //           backgroundColor: "#0A2F45",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.temp4),
    //         },
    //         {
    //           label: `P1`,
    //           borderColor: "#087E8B",
    //           backgroundColor: "#087E8B",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.pressure1),
    //         },
    //         {
    //           label: `P2`,
    //           borderColor: "#0F5C45",
    //           backgroundColor: "#0F5C45",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.pressure2),
    //         },
    //         {
    //           label: `P3`,
    //           borderColor: "#49C6E5",
    //           backgroundColor: "#49C6E5",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.pressure3),
    //         },
    //         {
    //           label: `V1`,
    //           borderColor: "#FF6900",
    //           backgroundColor: "#FF6900",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.voltage1),
    //         },
    //         {
    //           label: `V2`,
    //           borderColor: "#9E0059",
    //           backgroundColor: "#9E0059",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.voltage2),
    //         },
    //         {
    //           label: `V3`,
    //           borderColor: "#008ad6",
    //           backgroundColor: "#008ad6",
    //           borderWidth: 2,
    //           fill: false,
    //           data: this.currentData.map((item) => item.voltage3),
    //         },
    //       ],
    //     };
    //   } else if (this.deviceType === "TDX") {
    //     this.chartdata = {
    //       // labels: this.currentData.map(item => {
    //       //   let date = new Date(item.createdAt);
    //       //   return `${date.getMonth() + 1}/${date.getDate()} - ${
    //       //     date.getHours() < 13 ? date.getHours() : date.getHours() - 12
    //       //   }:${
    //       //     date.getMinutes() < 10
    //       //       ? "0" + date.getMinutes()
    //       //       : date.getMinutes()
    //       //   }`;
    //       // }),
    //       datasets: [
    //         {
    //           label: `Temp`,
    //           // borderColor: "green",
    //           // backgroundColor: "green",
    //           borderColor: (context) => {
    //             return this.chartGradient(context);
    //           },
    //           backgroundColor: (context) => {
    //             return this.chartGradient(context);
    //           },
    //           radius: 0,
    //           // pointStyle: "cross",
    //           borderWidth: 2,
    //           fill: false,
    //           // data: this.currentData.map(item => item.min)
    //           data: this.currentData.map((item) => {
    //             return {
    //               x: new Date(item.createdAt),
    //               y: item.min,
    //             };
    //           }),
    //         },
    //       ],
    //     };
    //   }
    // },
  },
  beforeDestroy() {
    if (this.realtime) {
      this.$store.dispatch("sensor/emptySingleSensor");
    }
  },
};
</script>

<style lang="scss">
.single-sensor {
  .kdx {
    .v-input--is-label-active {
      label {
        color: #fff;
      }
    }
  }
}
</style>